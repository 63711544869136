var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "merchantadd-container position-relative" }, [
    _c("div", { staticClass: "text-center sale-font mb-25 font-weight" }, [
      _vm._v(" 供货单号：" + _vm._s(_vm.merchantList.supplier_order_no) + " "),
    ]),
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "addForm",
            attrs: {
              "label-width": "120px",
              model: _vm.merchantList,
              inline: true,
              "hide-required-asterisk": true,
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "供货商姓名", prop: "fullname" } },
                          [
                            _vm.merchantList.supplier
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.merchantList.supplier.fullname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系电话", prop: "mobile" } },
                          [
                            _vm.merchantList.supplier
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.merchantList.supplier.mobile) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "采购员", prop: "fullname" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getSupplierBuyerName(
                                    _vm.merchantList.buyer
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "档口位置", prop: "carLicense" } },
                          [
                            _vm.merchantList.supplier
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.merchantList.supplier.stall) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "auto-table-flex mb-30" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        attrs: {
                          data: _vm.goodsData,
                          "max-height": "600",
                          border: true,
                          "header-cell-style": {
                            color: "#333333",
                            background: "#EFF6FF",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            width: "150",
                            align: "center",
                            label: "序号",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsName",
                            align: "center",
                            label: "主图",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return scope.row.product
                                    ? [
                                        _c("img", {
                                          staticClass: "kv pointer",
                                          attrs: {
                                            src: scope.row.product.kv,
                                            alt: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.getGlass(
                                                scope.row.product.kv
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "title",
                            align: "center",
                            label: "名称",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return scope.row.product
                                    ? [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$empty.empty(
                                                scope.row.product.title
                                              )
                                            ) +
                                              " (" +
                                              _vm._s(
                                                _vm.$empty.empty(
                                                  scope.row.product.sku_code
                                                )
                                              ) +
                                              ")"
                                          ),
                                        ]),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "grade",
                            align: "center",
                            label: "等级",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return scope.row.product
                                    ? [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$empty.empty(
                                                scope.row.product.grade
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "gross_weight",
                            align: "center",
                            label: "毛重(斤)",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return scope.row.product
                                    ? [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$empty.empty(
                                                scope.row.product.gross_weight
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "weight",
                            align: "center",
                            label: "净重(斤)",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return scope.row.product
                                    ? [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$empty.empty(
                                                scope.row.product.weight
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "product_amount",
                            align: "center",
                            label: "金额(元)",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$empty.empty(
                                          _vm.tool.format_money(
                                            scope.row.product_amount
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "product",
                            align: "center",
                            label: "非会员价",
                            "min-width": "100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (scope.row.product &&
                                          (
                                            scope.row.product.price / 100
                                          ).toFixed(2)) ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "membership_price",
                            align: "center",
                            label: "会员价",
                            "min-width": "100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (scope.row.product &&
                                          (
                                            scope.row.product.membership_price /
                                            100
                                          ).toFixed(2)) ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "product_number",
                            align: "center",
                            label: "数量",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.product
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$empty.empty(
                                              scope.row.product_number
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "fy1",
        staticStyle: {
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
        },
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showImage,
          expression: "showImage",
        },
      ],
      staticClass: "shadow",
      on: {
        click: function ($event) {
          _vm.showImage = false
        },
      },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showImage,
            expression: "showImage",
          },
        ],
        staticClass: "bigImage",
      },
      [
        _c("img", { attrs: { src: _vm.bigImage, alt: "" } }),
        _c("i", {
          staticClass: "el-icon el-icon-close bigClose",
          on: {
            click: function ($event) {
              _vm.showImage = false
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }